import {AnyLayer, Sources} from 'mapbox-gl';
import {FeatureCollection} from 'geojson';
import {SurveyDataType, SurveyDataUrls, SurveyVariable} from '../entities/Survey';
import {FIELD_COLOR, FIELD_LABEL_COLOR} from '../../config';

const FIELD_LAYERS: Array<AnyLayer> = [
  {
    'id': 'fields-fill',
    'source': 'fields',
    'type': 'fill',
    'paint': {
      'fill-color': FIELD_COLOR,
      'fill-opacity': 0.25,
    }
  },
  {
    'id': 'fields-outline',
    'source': 'fields',
    'type': 'line',
    'paint': {
      'line-color': FIELD_COLOR,
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 15, 2, 16, 3]
    }
  },
  {
    'id': 'fields-label',
    'source': 'fields',
    'type': 'symbol',
    'minzoom': 12,
    'layout': {
      'text-field': ['get', 'name'],
      'text-size': ['interpolate', ['linear'], ['zoom'], 10, 8, 18, 20, 22, 24],
      'text-font': ['Roboto Condensed Bold'],
    },
    'paint': {
      'text-color': FIELD_LABEL_COLOR,
      'text-halo-color': '#FFF',
      'text-halo-width': 1
    }
  }
];

const FIELDS_LAYERS_WITH_COG: Array<AnyLayer> = [
  FIELD_LAYERS[0],
  {
    'id': 'cog-layer',
    'type': 'raster',
    'source': 'cog'
  },
  FIELD_LAYERS[1],
  FIELD_LAYERS[2]
];

const getMapSourcesAndLayers = (
  fields: FeatureCollection, surveyDataUrls?: SurveyDataUrls, selectedVariable?: SurveyVariable
): {sources: Sources, layers: Array<AnyLayer>} => {
  const cogUrl = surveyDataUrls?.urls.find(url => url.variable === selectedVariable && url.type === SurveyDataType.COG)?.url;

  return {
    sources: getSources(fields, cogUrl),
    layers: getLayers(!!cogUrl)
  };
};

const getSources = (fields: FeatureCollection, cogUrl?: string) => ({
  fields: {
    type: 'geojson' as const,
    data: fields
  },
  ...cogUrl ? {
    cog: {
      type: 'raster' as const,
      url: `cog://${cogUrl}`,
      tileSize: 256
    }
  } : {}
});

const getLayers = (hasCog: boolean)=> hasCog ? FIELDS_LAYERS_WITH_COG : FIELD_LAYERS;

export default getMapSourcesAndLayers;
