import React, {FC} from 'react';
import MapLegend from '@geomatico/geocomponents/Map/MapLegend';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import {HEXColor} from '../domain/entities/HEXColor';

const getLabel = (from?: number, to?: number) => {
  const hasNone = from === undefined && to === undefined;
  if (hasNone) return '';

  const hasBoth = from !== undefined && to !== undefined;
  const areEqual = hasBoth && from === to;
  const hasFromOnly = !hasBoth && from !== undefined;

  const nexus = hasBoth ? '-' : hasFromOnly ? '>' : '<';

  const strFrom = from !== undefined ? String(Math.round(from * 100) / 100) : '';
  const strTo = to !== undefined ? String(Math.round(to * 100) / 100) : '';

  return areEqual ? strFrom : hasFromOnly ? `${nexus} ${strFrom}` : `${strFrom} ${nexus} ${strTo}`;
};

const paperSx = {
  display: 'inline-flex',
  flexDirection:'column',
  alignSelf: 'start',
  pr: 2,
  pt: 1,
  pl: 1,
  opacity: 0.85,
  position: 'absolute',
  bottom: 10,
  left: 10
};

export type LegendProps = {
  label?: string,
  min?: number,
  max?: number
  thresholds: Array<number>,
  colors: Array<HEXColor>,
  isVertical?: boolean
};

const Legend: FC<LegendProps> = ({label, min, max, thresholds, colors, isVertical=true}) => {

  const mapLegendSx = {
    '&.MapLegend-root': {
      display: 'flex',
      flexDirection: isVertical ? 'column' : 'row'
    },
    '& .MapLegend-swatch': {
      '& .MuiBox-root': {
        borderRadius: '2px',
        height: 20,
        width: 25
      }
    },
    '& .MapLegend-text': {
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap'
    }
  };

  if(colors.length !== thresholds.length + 1) {
    console.error(`colors needs to have ${thresholds.length + 1} elements and has ${colors.length}`);
    return null;
  }

  const values = [min, ...thresholds, max];

  const hasValues = values.some(v => v !== undefined);

  const fields = colors.map((color, i) => ({
    name: getLabel(values[i], values[i+1]),
    type: 'fill',
    paint: {
      color,
      outlineColor: 'lightgrey',
      outlineWidth: 1
    }
  }));

  if (!hasValues && !label) return null;

  return <Paper sx={paperSx}>
    {label ? <Typography variant='subtitle2' sx={{pl: 1}}>{label}</Typography> : null}
    {hasValues ? <MapLegend fields={fields} sx={mapLegendSx}/> : null}
  </Paper>;
};

export default Legend;
