import {SurveyDataUrlsDto, SurveyDto, SurveyDtoCreation} from './SurveyDto';
import {
  Survey,
  SurveyCreation,
  SurveyDataType,
  SurveyDataUrls,
  SurveyStatus,
  SurveyVariable
} from '../../domain/entities/Survey';
import RepositoryMapper, {toEnum} from '../../common/RepositoryMapper';

const surveyMapper: RepositoryMapper<Survey, SurveyDto> = {
  toDomain: (dto: SurveyDto): Survey => {
    return {
      ...dto,
      availableVariables: dto.availableVariables.map(variable => toEnum(SurveyVariable, variable)),
      status: toEnum(SurveyStatus, dto.status),
      startDate: dto.startDate ? new Date(dto.startDate) : undefined,
      endDate: dto.endDate ? new Date(dto.endDate) : undefined,
      variableStats: dto.variableStats.map(statsDto => ({
        ...statsDto,
        variable: toEnum(SurveyVariable, statsDto.variable),
      })),
      createdAt: new Date(dto.createdAt),
      updatedAt: new Date(dto.updatedAt)
    };
  },
  toDto: (domain: Survey): SurveyDto => {
    return {
      ...domain,
      startDate: domain.startDate?.toISOString() ?? null,
      endDate: domain.endDate?.toISOString() ?? null,
      createdAt: domain.createdAt.toISOString(),
      updatedAt: domain.updatedAt.toISOString()
    };
  },
  toDtoCreation: (domain: SurveyCreation): SurveyDtoCreation => {
    return {
      ...domain,
      startDate: domain.startDate?.toISOString() ?? null,
      endDate: domain.endDate?.toISOString() ?? null
    };
  }
};

export const toDomainDataUrls = (dto: SurveyDataUrlsDto): SurveyDataUrls => {
  return {
    ...dto,
    urls: dto.urls.map(url => ({
      ...url,
      variable: toEnum(SurveyVariable, url.variable),
      type: toEnum(SurveyDataType, url.type)
    })),
    expirationDate: new Date(dto.expirationDate)
  };
};

export default surveyMapper;
