import React, {FC} from 'react';
import Paper from '@mui/material/Paper';
import LogoTalptechMarron from './logos/LogoTalptechMarron';

const paperSx = {
  pt: 1,
  opacity: 0.85,
  position: 'absolute',
  top: 10,
  right: 10
};

const ScreenshotLogo: FC = () => {
  return <Paper sx={paperSx}>
    <LogoTalptechMarron width={240}/>
  </Paper>;
};

export default ScreenshotLogo;
