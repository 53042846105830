import React, {FC, useCallback, useMemo, useState} from 'react';

//MUI
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { lighten } from '@mui/material/styles';

//TALPTECH
import {Field} from '../domain/entities/Field';
import GeometryThumbnail from './GeometryThumbnail';

import {useTranslation} from 'react-i18next';

import {grey} from '@mui/material/colors';
import SearchBox from '@geomatico/geocomponents/Search/SearchBox';
import {FIELD_COLOR, FIELD_LABEL_COLOR} from '../config';

export interface FieldsTableProps {
  fields: Array<Field>,
  selectedField?: Field,
  onSelectedField: (field: Field) => void
}

const FieldsTable: FC<FieldsTableProps> = ({fields, selectedField, onSelectedField}) => {

  //STYLES
  const cellSx = {
    display: 'flex',
    flexDirection: 'row',
    alignItems:'center'
  };
  const myFieldName = (uuid: string) => (
    {
      pl: 2,
      color: selectedField && uuid === selectedField.uuid ? 'white' : 'inherit'
    }
  );
  const areaLabel = (uuid: string) => (
    {...myFieldName(uuid), width: 150}
  );
  const tableRow = (uuid: string) => (
    {
      backgroundColor: selectedField && uuid === selectedField.uuid ? FIELD_COLOR : 'inherit',
      cursor: 'pointer',
      '&:last-child td, &:last-child th': {border: 0},
      '&:hover': {
        backgroundColor: selectedField && uuid === selectedField.uuid ? 'secondary.main' : grey[100],
      }
    }
  );
  const scrollBarSx = {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '1px',
      paddingTop: 50
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: lighten('#000', 0.5),
      borderRadius: '2px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'red',
    },
    scrollbarColor: `${lighten('#000', 0.5)} transparent`,
    scrollbarWidth: 'thin',
  };

  const {t} = useTranslation();
  const [searchText, setSearchText] = useState<string>('');

  const handleSelectedField = (selectedFieldUuid: string) => {
    const field: Field | undefined = fields.find(field => field.uuid === selectedFieldUuid);
    field && onSelectedField(field);
  };

  const normalizeSearchString = (string: string) => string.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');

  const filteredFields = useMemo(() =>
    searchText ?
      fields
        .sort((a: Field, b: Field) => a.name > b.name ? 1 : -1)
        .filter(field => normalizeSearchString(field.name).includes(normalizeSearchString(searchText))) :
      fields
        .sort((a, b) => a.name > b.name ? 1 : -1)
  , [searchText, fields]);

  const handleSearchClick = useCallback(() => undefined, []);

  return <TableContainer component={Paper} sx={{...scrollBarSx, boxShadow: 0}}>
    <Table size='small' stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{width: '65%', border: 'none', px: 1}}>
            <SearchBox
              text={searchText}
              placeholder={t('search')}
              onSearchClick={handleSearchClick}
              onTextChange={setSearchText}
              sx={{
                '&.SearchBox-root': {
                  borderRadius: 0,
                  boxShadow: 0,
                  flexShrink: 0,
                  py: 1,
                  borderBottom: '1px solid lightgrey'
                },
                '& .SearchBox-endIcon': {
                  display: 'none'
                }
              }}
              dense/>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          filteredFields.map(({uuid, name, boundary, area}) => (
            <TableRow key={uuid} onClick={() => handleSelectedField(uuid)} sx={tableRow(uuid)}>
              <TableCell sx={cellSx}>
                <GeometryThumbnail boundary={boundary}
                  borderColor={uuid === selectedField?.uuid ? FIELD_LABEL_COLOR : FIELD_COLOR}
                  color={uuid === selectedField?.uuid ? 'white' : FIELD_COLOR}
                  opacity={uuid === selectedField?.uuid ? 1 : 0.25}
                />
                <Typography sx={myFieldName(uuid)}>{name}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" sx={areaLabel(uuid)}>{area.toFixed(2)} Ha</TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </TableContainer>
  ;
};

export default FieldsTable;
