import {VersionedEntityCreation} from '../../common/VersionedEntity';

export enum SurveyStatus {
  AWAITING_FILE = 'AWAITING_FILE',
  PENDING = 'PENDING',
  DONE = 'DONE',
  FAILED = 'FAILED'
}

export enum SurveyVariable {
  LAI_MEAN_LEFT = 'LAI_MEAN_LEFT',
  LAI_MEAN_RIGHT = 'LAI_MEAN_RIGHT',
  SPEED_MEAN = 'SPEED_MEAN',
  PRESSURE_MEAN = 'PRESSURE_MEAN',
}

export enum SurveyDataType {
  COG = 'COG',
  JPEG = 'JPEG'
}

export type VariableStats = {
  variable: SurveyVariable,
  mean: number,
  min: number,
  max: number,
  naturalBreaks: Array<number>
};

export type Survey = {
  uuid: string,
  fieldUuid: string,
  deviceUuid: string,
  startDate?: Date,
  endDate?: Date,
  status: SurveyStatus,
  availableVariables: Array<SurveyVariable>
  variableStats: Array<VariableStats>
  createdAt: Date
  updatedAt: Date
};

export type SurveyWithUploadUrls = Survey & {
  csvUploadUrl: string
}

export type SurveyDataUrl = {
  variable: SurveyVariable
  type: SurveyDataType
  url: string
}

export type SurveyDataUrls = {
  expirationDate: Date
  urls: Array<SurveyDataUrl>
}

export type SurveyUploadUrls = {
  csvUploadUrl: string
}


export type SurveyCreation = VersionedEntityCreation<Survey>;
