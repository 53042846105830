import React, {FC, SVGProps} from 'react';

const LogoTalptechMarron: FC<SVGProps<SVGSVGElement>> = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 350.09 168.48"
    {...props}
  >
    <circle fill="#7e6a5c" cx="172.41" cy="53.65" r="26.92"/>
    <path fill="#ffffff" d="m172.25 47a149.56 149.56 0 0 0 19-1.77v-5.79h-37.68v5.46a91 91 0 0 0 18.68 2.1z"/>
    <path fill="#ffffff" d="m172.26 51c-1.18 0-2.35 0-3.65-0.07v22.82h7.66v-22.91c-1.47 0.09-2.79 0.16-4.01 0.16z"/>
    <path fill="#7e6a5c" d="M95.61,113.69l3.33-8.22a1.69,1.69,0,0,1,3.18,0L105.6,114l3.57,8.48c.07.17.12.31.18.47h5.73l-.11-.26-8.35-19.27a6.65,6.65,0,0,0-12.32,0L86,122.68l-.11.26h5.88c0-.17.1-.33.16-.48l1.29-3.14Z"/>
    <path fill="#7e6a5c" d="m124.51 123.14h16.22v-5h-15.47c-1.92 0-2.64-0.78-2.64-2.89v-15.25h-5.06v15.8c0 5.35 1.89 7.34 6.95 7.34z"/>
    <path fill="#7e6a5c" d="M167.78,114a8.86,8.86,0,0,0-.51-11.53,8.39,8.39,0,0,0-6.53-2.44H145.4c-.68,0-.92.24-.92.92v22.21h5V117h11.24A8.5,8.5,0,0,0,167.78,114Zm-18.28-7.62a1,1,0,0,1,1-1.09h10.34a3.65,3.65,0,0,1,3.09,1.3,3.53,3.53,0,0,1,.71,2.2,3.43,3.43,0,0,1-.66,2.07,3.53,3.53,0,0,1-3.14,1.39H149.5Z"/>
    <path fill="#1d1d1b" d="M224.77,105.27V100H204.3c-1.86,0-2.7.82-2.7,2.68v17.65c0,1.94.83,2.8,2.67,2.8h20.5v-5h-17a1.11,1.11,0,0,1-1.13-1.23v-3.54h17.59v-3.5H206.63v-3.35a1.09,1.09,0,0,1,1.13-1.2Z"/>
    <path fill="#1d1d1b" d="M251.76,105.27V100H240.23a11.32,11.32,0,0,0-8.15,3,12.07,12.07,0,0,0-.72,16.34,11.18,11.18,0,0,0,8.87,3.76h11.53v-5.23H240.23a6.33,6.33,0,1,1,0-12.64Z"/>
    <polygon fill="#1d1d1b" points="281.14 123.14 281.14 100.01 276.09 100.01 276.09 108.81 261.11 108.81 261.11 100.01 256.06 100.01 256.06 123.14 261.11 123.14 261.11 113.75 276.09 113.75 276.09 123.14"/>
    <path fill="#1d1d1b" d="m185.06 104.93a101.94 101.94 0 0 0 12.9-1.2v-3.82h-25.56v3.63a63.17 63.17 0 0 0 12.66 1.39z"/>
    <path fill="#1d1d1b" d="m185.08 107.8h-2.41v15.39h5.06v-15.48c-0.98 0.05-1.85 0.08-2.65 0.09z"/>
    <path fill="#698063" d="M62.25,105.79v.06h.37Z"/>
    <polygon fill="#ffffff" stroke="#ffffff" strokeMiterlimit={10} strokeWidth="0.4px" points="62.62 106 62.1 106 62.1 105.61 62.64 105.71"/>
    <path fill="#7e6a5c" d="m75.3 105.11a104.56 104.56 0 0 0 13.19-1.23v-4.16h-25.94v4a60.73 60.73 0 0 0 12.75 1.39z"/>
    <path fill="#7e6a5c" d="m75.3 107.61h-2.48v15.76h5.43v-15.87c-1.09 0.07-2.05 0.1-2.95 0.11z"/>
  </svg>;
};

export default LogoTalptechMarron;
