import 'reflect-metadata';
import {HEXColor} from './domain/entities/HEXColor';
import {SurveyVariable} from './domain/entities/Survey';

export const DRAWER_WIDTH = 450;

export const API_BASE_URL = process.env.API_BASE_URL || '';

export const MAPSTYLE_URL = '/mapstyles/pnoa.json';

export const INITIAL_VIEWPORT = {
  latitude: 41.6779,
  longitude: 0.4936,
  zoom: 12.5,
  bearing: 0,
  pitch: 0
};

export const FIELD_COLOR = 'hsl(337, 155%, 27%)';
export const FIELD_LABEL_COLOR = 'hsl(337, 155%, 12%)';

export const COLOR_SCALE: Array<HEXColor> = [
  '#fe1919',
  '#ffff1a',
  '#66ff19',
  '#47c7ff',
  '#1919fe'
];

export const VARIABLE_UNITS: Record<SurveyVariable, string> = {
  LAI_MEAN_LEFT: '',
  LAI_MEAN_RIGHT: '',
  SPEED_MEAN: 'km/h',
  PRESSURE_MEAN: 'bar'
};
