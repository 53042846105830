import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {MapRef} from 'react-map-gl';
import {locationValues, setColorFunction} from '@geomatico/maplibre-cog-protocol';

import {COLOR_SCALE, VARIABLE_UNITS} from '../../config';
import {Survey, SurveyDataType, SurveyDataUrls, SurveyVariable} from '../../domain/entities/Survey';
import {MapLayerMouseEvent} from 'mapbox-gl';
import {FeatureCollection, MultiPolygon} from 'geojson';

import bbox from '@turf/bbox';

import SurveySelectVariable from '../../components/SurveySelectVariable';
import SurveyDatePicker from '../../components/SurveyDatePicker';
import FormControl from '@mui/material/FormControl';
import {SxProps} from '@mui/material';
import {Field} from '../../domain/entities/Field';
import getMapSourcesAndLayers from '../../domain/useCases/getMapSourcesAndLayers';
import Tooltip from '../../components/Tooltip';
import TalptechMap from '../../components/TalptechMap';
import Legend from '../../components/Legend';
import ZoomToExtentIcon from '@mui/icons-material/CropFree';
import getThresholdColorFunction from '../../domain/useCases/getThresholdColorFunction';
import IconButton from '@mui/material/IconButton';

const selectsPosition: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  position: 'absolute',
  gap: 2,
  top: 30,
  left: 15
};

const zoomToExtentButtonSx: SxProps = {
  position: 'absolute',
  top: 8,
  right: 8,
  borderRadius: 1,
  bgcolor: 'common.white',
  color: 'text.primary',
  '&:hover': {
    backgroundColor: 'common.white'
  },
};

const asFeatureCollection = (fields: Array<Field>): FeatureCollection<MultiPolygon> => ({
  'type': 'FeatureCollection',
  'features': fields.map(field => ({
    'type': 'Feature',
    'properties': {
      'uuid': field.uuid,
      'ownerUuid': field.ownerUuid,
      'name': field.name,
      'area': field.area,
      'createdAt': field.createdAt,
      'updatedAt': field.updatedAt,
    },
    'geometry': field.boundary
  }))
});

export type MainContentProps = {
  fields: Array<Field>,
  selectedField?: Field,
  surveyDataUrls?: SurveyDataUrls,
  surveyStats?: Survey['variableStats'],
  variables?: Array<SurveyVariable>,
  selectedVariable?: SurveyVariable,
  dates?: Array<Date>
  selectedDate?: Date,
  onVariableChange: (variable: SurveyVariable) => void,
  onDateChange: (date: Date) => void,
  onSelectedField: (field?: Field) => void
};

const MainContent: FC<MainContentProps> = ({
  fields,
  selectedField,
  surveyDataUrls,
  surveyStats,
  variables,
  selectedVariable,
  dates,
  selectedDate,
  onVariableChange,
  onDateChange,
  onSelectedField
}) => {
  const mapRef = useRef<MapRef>(null);

  const fieldsFeatureCollection = useMemo(() =>
    fields && asFeatureCollection(fields)
  , [fields]);

  const {sources, layers} = useMemo(() =>
    getMapSourcesAndLayers(fieldsFeatureCollection, surveyDataUrls, selectedVariable)
  , [fieldsFeatureCollection, surveyDataUrls, selectedVariable]);

  const variableStats = surveyStats?.find(stat => stat.variable === selectedVariable);
  const cogUrl = surveyDataUrls?.urls.find(url => url.variable === selectedVariable && url.type === SurveyDataType.COG)?.url;

  useEffect(() => {
    if (variableStats && cogUrl) {
      try {
        setColorFunction(cogUrl, getThresholdColorFunction(variableStats.naturalBreaks, COLOR_SCALE));
      } catch (e) {
        console.error(e);
      }
    }
  }, [variableStats, cogUrl]);

  const [tooltipValue, setTooltipValue] = useState(Number.NaN);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number, left: number }>();

  const unit = selectedVariable ? VARIABLE_UNITS[selectedVariable] : '';

  const fitBounds = useCallback(({immediate, field}: {immediate?: boolean, field?: Field} = {}) => {
    if (fieldsFeatureCollection) {
      const [xMin, yMin, xMax, yMax] = field ? bbox(field.boundary) : bbox(fieldsFeatureCollection);
      mapRef.current?.fitBounds([xMin, yMin, xMax, yMax], {
        duration: immediate ? 0 : 2000,
        padding: 50
      });
    }
  }, [fieldsFeatureCollection]);

  const fitInitialBounds = useCallback(() => {
    fitBounds({immediate: true});
  }, []);

  const recenterMap = () => {
    if (selectedField) {
      onSelectedField();
    } else {
      fitBounds();
    }
  };

  useEffect(() => {
    fitBounds({field: selectedField});
  }, [selectedField]);

  const handleMapClick = (e: MapLayerMouseEvent) => {
    const feature = e.features && e.features[0];
    const field = fields.find(field => field.uuid === feature?.properties?.uuid);
    if (field) {
      onSelectedField(field);
    }
  };

  const handleMouseMove = (event: MapLayerMouseEvent) => {
    setTooltipPosition({left: event.originalEvent.pageX, top: event.originalEvent.pageY});

    const cogUrl = surveyDataUrls?.urls
      .find(url => url.variable === selectedVariable && url.type === SurveyDataType.COG)?.url;
    if (cogUrl) {
      locationValues(cogUrl, {latitude: event.lngLat.lat, longitude: event.lngLat.lng}, mapRef.current?.getZoom())
        .then(values => setTooltipValue(values?.length ? values[0] : Number.NaN))
        .catch(error => {
          setTooltipValue(Number.NaN);
          console.error(error);
        });
    }
  };

  return <TalptechMap
    ref={mapRef}
    onClick={handleMapClick}
    onMouseMove={handleMouseMove}
    sources={sources}
    layers={layers}
    interactiveLayerIds={['fields-fill']}
    onLoad={fitInitialBounds}
  >
    {variables && dates && selectedVariable && selectedDate && <>
      {variableStats ? <Legend
        min={variableStats.min} max={variableStats.max}
        thresholds={variableStats.naturalBreaks} colors={COLOR_SCALE}
        label={`${selectedVariable} ${unit}`}
      /> : null}
      <FormControl sx={selectsPosition}>
        <SurveySelectVariable variables={variables} selectedVariable={selectedVariable}
          onVariableChange={onVariableChange}/>
        <SurveyDatePicker availableDates={dates} selectedDate={selectedDate} onDateChange={onDateChange}/>
      </FormControl>
    </>}
    <IconButton onClick={recenterMap} sx={zoomToExtentButtonSx}> <ZoomToExtentIcon/> </IconButton>
    {!Number.isNaN(tooltipValue) && tooltipPosition &&
      <Tooltip {...tooltipPosition}>{tooltipValue.toFixed(2)} {unit}</Tooltip>
    }
  </TalptechMap>;

};

export default MainContent;
