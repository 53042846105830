import React, {FC, useCallback, useEffect, useState} from 'react';
import {AnyLayer, Sources} from 'mapbox-gl';
import {MapRef} from 'react-map-gl';
import {setColorFunction} from '@geomatico/maplibre-cog-protocol';

import getCogBbox from '../domain/useCases/getCogBbox';
import getThresholdColorFunction from '../domain/useCases/getThresholdColorFunction';

import TalptechMap from './TalptechMap';
import Legend from './Legend';

import {HEXColor} from '../domain/entities/HEXColor';
import {BBOX} from '../domain/entities/BBOX';
import ScreenshotLogo from './ScreenshotLogo';

const FIT_BBOX_OPTIONS = {
  padding: 20,
  animate: false
};

export interface ScreenshotMapProps {
  cogUrl: string
  bbox?: BBOX
  legendTitle?: string
  thresholds: Array<number>
  colors: Array<HEXColor>
  min?: number
  max?: number
}

const ScreenshotMap: FC<ScreenshotMapProps> = ({cogUrl, bbox: bboxProp, legendTitle, thresholds, colors, min, max}) => {

  const [bbox, setBbox] = useState<BBOX>();
  const [isReady, setReady] = useState<boolean>();

  useEffect(() => {
    bboxProp ? setBbox(bboxProp) : getCogBbox(cogUrl).then(setBbox);
  }, [bboxProp, cogUrl]);

  useEffect(() => {
    setColorFunction(cogUrl, getThresholdColorFunction(thresholds, colors));
  }, [cogUrl, thresholds, colors]);

  const handleRef = useCallback((map: MapRef | null) => {
    if (map && bbox) {
      map.on('load', () => {
        map.fitBounds(bbox, FIT_BBOX_OPTIONS);
        map.once('idle', () => {
          setReady(true);
        });
      });
    }
  }, [bbox]);

  const sources: Sources = {
    cogSource: {
      type: 'raster',
      url: `cog://${cogUrl}`,
      tileSize: 256
    }
  };

  const layers: Array<AnyLayer> = [{
    id: 'cogLayer',
    source: 'cogSource',
    type: 'raster'
  }];

  return <>
    <TalptechMap ref={handleRef} sources={sources} layers={layers}>
      <ScreenshotLogo/>
      <Legend label={legendTitle} thresholds={thresholds} colors={colors} min={min} max={max}/>
    </TalptechMap>
    {isReady ? <div id="ready"/> : null}
  </>;
};

export default ScreenshotMap;
