import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import Alert from '@mui/material/Alert';

import Layout from '../../components/Layout';
import SidePanelContent from './SidePanelContent';
import Loading from '../../components/Loading';
import MainContent from './MainContent';

import {useAsync} from 'react-use';
import {useTranslation} from 'react-i18next';

import getAllFields from '../../domain/useCases/getAllFields';
import getDoneSurveys from '../../domain/useCases/getSurveys';

import {Survey, SurveyDataUrls, SurveyVariable} from '../../domain/entities/Survey';
import getSurveyDataUrls from '../../domain/useCases/getSurveyDataUrls';
import {setAuthTokens} from '../../services/TalptechApiHttp';
import {Field} from '../../domain/entities/Field';
import getUserFriendlyApiErrorMessage from '../../domain/useCases/getUserFriendlyApiErrorMessage';

const PARAM_ACCESS_TOKEN = 'accessToken';
const PARAM_REFRESH_TOKEN = 'refreshToken';

const getAllVariables = (surveys: Array<Survey>): Array<SurveyVariable> => {
  if (!surveys) return [];

  const variables = surveys.flatMap(survey => survey.availableVariables);
  return [...new Set(variables)].sort();
};

const getDatesForVariable = (surveys?: Array<Survey>, selectedVariable?: SurveyVariable): Array<Date> => {
  if (!surveys || !selectedVariable) return [];

  const dates = surveys
    .filter(survey => survey.availableVariables.includes(selectedVariable))
    .map(survey => survey.startDate);

  return [...new Set(dates.filter((date): date is Date => !!date))]
    .sort((a, b) => a.getTime() - b.getTime());
};

const Index = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {selectedFieldUuid} = useParams<string>();
  const [apiError, setApiError] = useState<string>();

  // authentication tokens come as search params
  const [searchParams] = useSearchParams();
  setTokenFromQueryParams(searchParams);

  // survey-related state
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedVariable, setSelectedVariable] = useState<SurveyVariable>();
  const [surveyDataUrls, setSurveyDataUrls] = useState<SurveyDataUrls>();

  const {error: errorFields, loading: loadingFields, value: fields} = useAsync(getAllFields, []);
  const selectedField = useMemo(
    () => fields && fields.find(field => field.uuid === selectedFieldUuid),
    [fields, selectedFieldUuid]);

  const {error: errorSurveys, value: surveys} = useAsync(() => getDoneSurveys(selectedField), [selectedFieldUuid]);
  const selectedSurvey = useMemo(() => getSelectedSurvey(surveys, selectedDate), [surveys, selectedDate]);
  useEffect(() => {
    getSurveyDataUrls(selectedSurvey?.uuid)
      .then(setSurveyDataUrls)
      .catch(() => setApiError(t('errorGettingSurveys')));
  }, [selectedSurvey]);

  useEffect(() => {
    if (!surveyDataUrls) return;
    const timeout = surveyDataUrls.expirationDate.getTime() - new Date().getTime() - 60_1000;
    const timer = setTimeout(() => getSurveyDataUrls(selectedSurvey?.uuid)
      .then(setSurveyDataUrls)
      .catch(() => setApiError(t('errorGettingSurveys'))), timeout);
    return () => clearTimeout(timer);
  }, [surveyDataUrls]);

  const dates = useMemo(() => getDatesForVariable(surveys, selectedVariable), [surveys, selectedVariable]);
  const variables = useMemo(() => surveys && getAllVariables(surveys), [surveys]);

  useEffect(() => dates && setSelectedDate(dates[dates.length - 1]), [dates]);
  useEffect(() => variables && setSelectedVariable(variables[0]), [variables]);

  if (errorFields) {
    const cause = getUserFriendlyApiErrorMessage(errorFields);
    return <Alert severity='error'>{t('errorGettingFields')}: {cause}</Alert>;
  }

  if (errorSurveys) {
    const cause = getUserFriendlyApiErrorMessage(errorSurveys);
    return <Alert severity='error'>{t('errorGettingSurveys')} {cause}</Alert>;
  }

  if (loadingFields) {
    return <Loading/>;
  }

  if (!fields) {
    return <Alert severity='error'>{t('errorNoFields')}</Alert>;
  }

  const handleSelectedField = (field: Field | undefined) => {
    const queryParams =
      `${PARAM_ACCESS_TOKEN}=${encodeURIComponent(searchParams.get(PARAM_ACCESS_TOKEN) || '')}` +
      `&${PARAM_REFRESH_TOKEN}=${encodeURIComponent(searchParams.get(PARAM_REFRESH_TOKEN) || '')}`;
    field ? navigate(`../map/${field.uuid}?${queryParams}`) : navigate(`../map/?${queryParams}`);
  };

  const sidePanelContent = <SidePanelContent
    fields={fields}
    selectedField={selectedField}
    onSelectedField={handleSelectedField}
  />;

  const handleDateChange = (date: Date) => {
    if (!dates || !selectedDate) return;
    setSelectedDate(date);
  };

  const mainContent = <MainContent
    fields={fields}
    selectedField={selectedField}
    surveyDataUrls={surveyDataUrls}
    surveyStats={selectedSurvey?.variableStats}
    variables={variables}
    selectedVariable={selectedVariable}
    dates={dates}
    selectedDate={selectedDate}
    onVariableChange={setSelectedVariable}
    onDateChange={handleDateChange}
    onSelectedField={handleSelectedField}
  />;

  return <>
    <Layout
      sidePanelContent={sidePanelContent}
      mainContent={mainContent}
    />
    {apiError ? <Alert severity="error">{apiError}</Alert> : null}
  </>;
};

const getSelectedSurvey = (surveys?: Survey[], date?: Date): Survey | undefined => {
  if (!surveys || !date) return;

  const surveysForDate = surveys.filter(survey => {
    return survey.startDate?.getTime() === date.getTime();
  });
  return surveysForDate[0]; // Return first survey for the given date, ignore others (this should never happen 🪄)
};

const setTokenFromQueryParams = (searchParams: URLSearchParams) => {
  const accessToken = searchParams.get(PARAM_ACCESS_TOKEN);
  if (accessToken) {
    setAuthTokens(accessToken);
  }
};

export default Index;
